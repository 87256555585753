import React, { useEffect, useState } from 'react';
import styles from './Reports.module.css';
import CommitmentApi from '../../api/commitmentApi';
import { useSelector } from 'react-redux';
import { intlDateFormatNumeric } from '../../util/date';
import Loader from '../Common/Loader/Loader';

const ALIGNMENT_MAP = {
  incidentDate: 'left',
  tourName: 'left',
  tournamentName: 'left',
  round: 'center',
  startHole: 'center',
  numberHolesTimed: 'center',
  groupNumber: 'center',
  notificationDate: 'center',
  hole: 'center',
  stroke: 'center',
  secondsTaken: 'center',
  notificationTime: 'center',
  secondsField: 'left',
  secondsPlayer: 'left',
};

const TIMINGS_META = {
  averageStrokeTimeViolations: {
    title: 'Average Stroke Time Violations',
    countMapProp: 'avgStrokeTimeViolationCountMap',
    columns: {
      tourName: 'Tour',
      tournamentName: 'Tournament',
      secondsPlayer: 'Player Avg',
      secondsField: 'Field Avg',
    },
  },
  excessiveAverageStrokeTimeViolations: {
    title: 'Excessive Average Stroke Time Violations',
    countMapProp: 'excessiveAvgStrokeTimeViolationCountMap',
    columns: {
      tourName: 'Tour',
      tournamentName: 'Tournament',
      secondsPlayer: 'Player Avg',
      secondsField: 'Field Avg',
    },
  },

  paceOfPlayTimings: {
    title: 'Pace of Play Timings',
    countMapProp: 'timingCountMap',
    columns: {
      incidentDate: 'Incident Date',
      tourName: 'Tour',
      tournamentName: 'Tournament',
      round: 'Round',
      startHole: 'Starting Hole',
      numberHolesTimed: 'Holes Timed',
      groupNumber: 'Group',
      notificationDate: 'Date Notified',
    },
  },
  badTimings: {
    title: 'Bad Times',
    countMapProp: 'badTimingCountMap',
    columns: {
      incidentDate: 'Incident Date',
      tourName: 'Tour',
      tournamentName: 'Tournament',
      round: 'Round',
      hole: 'Hole',
      stroke: 'Stroke',
      secondsTaken: 'Time Taken',
      notificationDate: 'Date Notified',
    },
  },
  excessiveShotTimes: {
    title: 'Excessive Shot Times',
    countMapProp: 'excessiveShotTimeCountMap',
    columns: {
      incidentDate: 'Incident Date',
      tourName: 'Tour',
      tournamentName: 'Tournament',
      round: 'Round',
      hole: 'Hole',
      stroke: 'Stroke',
      secondsTaken: 'Time Taken',
      notificationDate: 'Date Notified',
    },
  },
};

export default function Timings({ globalStyles }) {
  const [timingsObj, setTimingsObj] = useState();
  const { profile, token } = useSelector((state) => state);

  useEffect(() => {
    CommitmentApi.getPlayerTimings(profile.id, token.id_token).then((res) => setTimingsObj(res.data));
  }, [profile.id, token.id_token]);

  function renderTimingsTableForOpts(propertyName, options = {}) {
    const { theCountMapJSON, theTimingsObj } = options;
    const countKeyYears = Object.keys(theCountMapJSON).sort((a, b) => b - a);
    const columns = Object.keys(TIMINGS_META[propertyName].columns);
    return (
      <div className={styles.TimingsContent} key={propertyName + options.title}>
        <div className={styles.TableContainer}>
          <table>
            <thead>
              {options.title && (
                <tr>
                  <th className={styles.WarningsHead} colSpan={columns.length}>
                    {options.title}
                  </th>
                </tr>
              )}
              <tr>
                {columns.map((property) => (
                  <th
                    key={property}
                    style={TIMINGS_META[propertyName].columnWidths ? { width: TIMINGS_META[propertyName].columnWidths[property] } : {}}
                    align={ALIGNMENT_MAP[property]}
                  >
                    {TIMINGS_META[propertyName].columns[property]}
                  </th>
                ))}
              </tr>
            </thead>
            {countKeyYears.map((year, i) => (
              <tbody id={year} key={i}>
                <tr>
                  <td colSpan={columns.length} className={styles.ReportSectionRow}>
                    {year} - {options.title || TIMINGS_META[propertyName].title} ({theCountMapJSON[year]})
                  </td>
                </tr>
                {theTimingsObj[propertyName]
                  .filter((timing) => timing.year.toString() === year.toString())
                  .map((timing, t) => (
                    <tr key={t}>
                      {columns.map((prop) => (
                        <td key={prop} align={ALIGNMENT_MAP[prop]}>
                          {prop.includes('Date') ? intlDateFormatNumeric(timing[prop]) : timing[prop]} {prop.includes('seconds') && 'seconds'}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            ))}
          </table>
        </div>
      </div>
    );
  }

  function renderTimingsTable(propertyName) {
    const { countMapProp } = TIMINGS_META[propertyName];

    const theCountMapJSON = JSON.parse(JSON.stringify(timingsObj[countMapProp]));
    return renderTimingsTableForOpts(propertyName, { theTimingsObj: timingsObj, theCountMapJSON });
  }

  if (!timingsObj) {
    return <Loader withWrapper />;
  } else {
    const timingKeys = Object.keys(TIMINGS_META);
    return (
      <div className={styles.MainContainer}>
        <div className={styles.ReportContainer} style={{ minHeight: globalStyles.windowHeight }}>
          <h1>Player Timings</h1>
          <div className={styles.HeaderContent}>
            <p className={styles.PlayerName}>
              <strong>{timingsObj.playerName}</strong>
            </p>
            <p>
              <strong>Date of Birth: </strong>
              {intlDateFormatNumeric(timingsObj.birthdate)}
            </p>
            <p>
              <strong>Country of Birth: </strong>
              {timingsObj.nationality}
            </p>
            <p>
              <strong>Season: </strong>
              {timingsObj.maxSeason || 'n/a'}
            </p>
            {timingKeys.map((prop) => (
              <p key={prop}>
                <strong>{TIMINGS_META[prop].title}: </strong>
                {timingsObj[TIMINGS_META[prop].countMapProp][timingsObj.maxSeason] || 0}
              </p>
            ))}
          </div>
          {Object.keys(TIMINGS_META).map((key) => (
            <div key={key}>
              <h2>{TIMINGS_META[key].title}</h2>
              {renderTimingsTable(key)}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
